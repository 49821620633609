<template>
	<ion-toolbar style="position: fixed">
		<ion-segment
			@ionChange="segmentChanged($event)"
			scrollable
			value="beginner"
		>
			<ion-segment-button value="beginner" class="segmentButton">
				<ion-label class="">BEGINNER</ion-label>
			</ion-segment-button>
			<ion-segment-button value="intermediate" class="segmentButton">
				<ion-label class="">INTERMEDIATE</ion-label>
			</ion-segment-button>
			<ion-segment-button value="advanced" class="segmentButton">
				<ion-label class="">ADVANCED</ion-label>
			</ion-segment-button>
		</ion-segment>
	</ion-toolbar>

	<div class="programs">
		<ion-button
			fill="clear"
			color="primary"
			class="bottomButton"
			@click="ownProgramHandler"
			>USE MY OWN PROGRAM INSTEAD</ion-button
		>
		<div id="beginnerSeleced" class="container" v-if="this.showBeginner">
			<ion-list
				v-for="program in beginnerPrograms"
				:key="program.uid"
				class="ion-no-padding"
			>
				<base-card
					:smallText="program.difficulty"
					:bigText="program.name"
					buttonText="SELECT PROGRAM"
					:mainBody="program.description"
					class="card"
					width="90"
					:buttonEvent="program.uid"
					v-on="{ [program.uid]: programSelectedHandler }"
				/>
			</ion-list>
			<div class="useProgramInstead"></div>
		</div>
		<div
			id="intermediateSelected"
			class="container"
			v-if="this.showIntermediate"
		>
			<ion-list
				v-for="program in intermediatePrograms"
				:key="program.uid"
				class="ion-no-padding"
			>
				<base-card
					:smallText="program.difficulty"
					:bigText="program.name"
					buttonText="SELECT PROGRAM"
					:mainBody="program.description"
					class="card"
					width="90"
					:buttonEvent="program.uid"
					v-on="{ [program.uid]: programSelectedHandler }"
				/>
			</ion-list>
			<div class="useProgramInstead"></div>
		</div>
		<div id="advancedSelected" class="container" v-if="this.showAdvanced">
			<ion-list
				v-for="program in advancedPrograms"
				:key="program.uid"
				class="ion-no-padding"
			>
				<base-card
					:smallText="program.difficulty"
					:bigText="program.name"
					buttonText="SELECT PROGRAM"
					:mainBody="program.description"
					class="card"
					width="90"
					:buttonEvent="program.uid"
					v-on="{ [program.uid]: programSelectedHandler }"
				/>
			</ion-list>
			<div class="useProgramInstead"></div>
		</div>
	</div>
</template>

<script>
	import {
		IonSegment,
		IonSegmentButton,
		IonLabel,
		IonToolbar,
		IonList,
		IonButton,
	} from "@ionic/vue";
	import { set } from "@/services/storage.js";

	export default {
		name: "program-detail",
		components: {
			IonSegmentButton,
			IonSegment,
			IonLabel,
			IonToolbar,
			IonList,
			IonButton,
		},
		computed: {
			begStyle() {
				console.debug("i have been executed beginner");
				let displayStyle =
					this.segmentSelected == "beginner" ? "container" : "none";
				return {
					display: displayStyle,
				};
			},
			intStyle() {
				console.debug("i have been executed inter");
				let displayStyle =
					this.segmentSelected == "intermediate" ? "container" : "none";
				return {
					display: displayStyle,
				};
			},
			adStyle() {
				console.debug("i have been executed adv");
				let displayStyle =
					this.segmentSelected == "advanced" ? "container" : "none";
				return {
					display: displayStyle,
				};
			},
		},
		methods: {
			segmentChanged(event) {
				const selectedSegment = event.detail.value;
				console.debug(selectedSegment);
				this.segmentSelected = selectedSegment;
				this.showBeginner = selectedSegment == "beginner" ? true : false;
				this.showIntermediate =
					selectedSegment == "intermediate" ? true : false;
				this.showAdvanced = selectedSegment == "advanced" ? true : false;
			},
			ownProgramHandler() {
				this.$emit("done", false);
			},
			programSelectedHandler(id) {
				console.debug("handler", id);
				set("programName", id);
				this.$emit("done", true);
			},
		},
		data() {
			return {
				beginnerPrograms: [
					{
						name: "Beginner 5K Plan",
						difficulty: "BEGINNER",
						description: "",
						uid: "beginner5k",
					},
					{
						name: "Improver 5K Plan",
						difficulty: "IMPROVER",
						description: "",
						uid: "imp5k",
					},
					{
						name: "Improver 10K Plan",
						difficulty: "IMPROVER",
						description: "",
						uid: "imp10k",
					},
				],
				intermediatePrograms: [
					{
						name: "Young Athletes Middle Distance Plan",
						difficulty: "SPECIFIC",
						description: "",
						uid: "yamd",
					},
					{
						name: "Masters 5K Plan",
						difficulty: "SPECIFIC",
						description: "",
						uid: "masters5k",
					},
					{
						name: "Multisport Middle Distance Plan",
						difficulty: "SPECIFIC",
						description: "",
						uid: "multisportmd",
					},
				],
				advancedPrograms: [
					{
						name: "Performance 800m Plan",
						difficulty: "ELITE",
						description: "",
						uid: "perf800",
					},
					{
						name: "Performance 1500m Plan",
						difficulty: "ELITE",
						description: "",
						uid: "perf1500",
					},
					{
						name: "Performance 5K Plan",
						difficulty: "ELITE",
						description: "",
						uid: "perf5k",
					},
					{
						name: "Performance 10K Plan",
						difficulty: "ELITE",
						description: "",
						uid: "perf10k",
					},
					{
						name: "Performance Half Marathon Plan",
						difficulty: "ELITE",
						description: "",
						uid: "performancehm",
					},
					{
						name: "Performance Marathon Plan",
						difficulty: "ELITE",
						description: "",
						uid: "performancem",
					},
				],
				segmentSelected: "advanced",
				showBeginner: true,
				showIntermediate: false,
				showAdvanced: false,
			};
		},
	};
</script>

<style scoped>
	.segmentButton {
		--color: var(--ion-color-medium-tint);
		--color-checked: var(--ion-color-primary);
	}
	.container {
		position: relative;
		left: 5%;
		top: 10px;
		width: 90%;
		height: fit-content;
		/*border: 2px solid red;*/
	}
	.card {
		position: relative;
		left: -15px;
		/*border: 2px green solid;*/
	}
	.list {
		/*border: 2px solid blue;*/
		position: relative;
		padding: none;
	}
	.footer {
		/*position: fixed;*/
		width: 100%;
		justify-content: center;
		background-color: var(--ion-color-tertiary-tint);
	}
	.bottomButton {
		text-align: center;
		/*border: 2px solid yellow;*/
		width: 100%;
		position: relative;
		top: 15px;
	}
	.useProgramInstead {
		width: 100%;
		/*border: 2px solid purple;*/
	}
	.programs {
		position: relative;
		top: 45px;
	}
</style>
