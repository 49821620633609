<template>
	<base-layout>
		<template v-slot:toolbar>
			<ion-buttons slot="end">
				<ion-back-button
					color="light"
					default-href="/signin/signup/details1"
					@click="backStage"
				/>
			</ion-buttons>
		</template>
		<program-details @done="finishedStage" />
	</base-layout>
</template>

<script>
	import ProgramDetails from "../Userdetails/ProgramDetails.vue";
	import { signupWriteProfile } from "@/services/api.js";
	import { IonButtons, IonBackButton } from "@ionic/vue";

	export default {
		components: { ProgramDetails, IonButtons, IonBackButton },
		methods: {
			async finishedStage(usesPrograms) {
				switch (usesPrograms) {
					case true:
						this.$router.push("/signin/signup/details3");
						break;
					case false:
						await signupWriteProfile(false);
						this.$router.push("/signin/signup/details4");
				}
			},
			backStage() {
				this.$router.push("/signin/signup/details1");
			},
		},
	};
</script>

<style scoped></style>
